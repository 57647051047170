import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import './styles.css';

import { BrowserRouter } from 'react-router';

const PhotoUpload = lazy(() => import('./components/photo-upload/PhotoUpload'));
const PostPurchaseUpsells = lazy(
  () => import('./components/post-purchase-upsells/PostPurchaseUpsells')
);
const Giftboxes = lazy(() => import('./components/giftboxes/Giftboxes'));

const postPurchaseUpsellsMount = document.getElementById(
  'divvyup-post-purchase-upsells'
);

if (postPurchaseUpsellsMount) {
  createRoot(postPurchaseUpsellsMount).render(
    <BrowserRouter>
      <PostPurchaseUpsells
        upsells={JSON.parse(postPurchaseUpsellsMount.dataset.upsells as string)}
        order={JSON.parse(postPurchaseUpsellsMount.dataset.order as string)}
        pairs={parseInt(postPurchaseUpsellsMount.dataset.pairs as string)}
        blankets={parseInt(postPurchaseUpsellsMount.dataset.blankets as string)}
        acceptUrl={postPurchaseUpsellsMount.dataset.acceptUrl as string}
        doneUrl={postPurchaseUpsellsMount.dataset.doneUrl as string}
        nonce={postPurchaseUpsellsMount.dataset.nonce as string}
        expires={parseInt(postPurchaseUpsellsMount.dataset.expires as string)}
        content={JSON.parse(postPurchaseUpsellsMount.dataset.content as string)}
        signingsUrl={postPurchaseUpsellsMount.dataset.signingsUrl as string}
        imageProxyUrl={postPurchaseUpsellsMount.dataset.imageProxyUrl as string}
      />
    </BrowserRouter>
  );
}

const photoUploadMount = document.getElementById('divvyup-photo-upload');

if (photoUploadMount) {
  createRoot(photoUploadMount).render(
    <PhotoUpload
      product={JSON.parse(photoUploadMount.dataset.product as string)}
      content={JSON.parse(photoUploadMount.dataset.content as string)}
      settings={JSON.parse(photoUploadMount.dataset.settings as string)}
      signingsUrl={photoUploadMount.dataset.signingsUrl as string}
      imageProxyUrl={photoUploadMount.dataset.imageProxyUrl as string}
    />
  );
}

const giftboxesMount = document.querySelector(
  '.woo-photo-upload-giftbox'
) as HTMLElement;

if (giftboxesMount) {
  giftboxesMount.classList.add('divvyup-tw');
  createRoot(giftboxesMount).render(
    <Giftboxes
      giftboxes={JSON.parse(giftboxesMount.dataset.giftboxes as string)}
      heading={giftboxesMount.dataset.heading as string}
      information={giftboxesMount.dataset.information as string}
    />
  );
}
